import propTypes from 'prop-types';
import React from 'react';

import { Label as CustomLabel, TextAreaField, TextareaFieldWrapper, TextareaLabelWrapper } from './styles';

export const TextAreaLabel = React.forwardRef(
  ({ id, label, name, readOnly, onChange, required, displayOptionalLabel, tabindex }, ref) => {
    const customId = id || `input-label--${label}`;

    return (
      <TextareaLabelWrapper className="textarea-label">
        <CustomLabel htmlFor={customId}>
          {label}
          {required && <span className="required">*</span>} {label ? ':' : ''}
          {!required && displayOptionalLabel && <i> (opcional)</i>}
        </CustomLabel>
        <TextareaFieldWrapper>
          <TextAreaField
            id={customId}
            name={name}
            ref={ref}
            onChange={onChange}
            required={required}
            readOnly={readOnly}
            tabIndex={tabindex}
          />
        </TextareaFieldWrapper>
      </TextareaLabelWrapper>
    );
  },
);

TextAreaLabel.propTypes = {
  id: propTypes.string.isRequired,
  label: propTypes.string,
  name: propTypes.string,
  readOnly: propTypes.bool,
  onChange: propTypes.func.isRequired,
  required: propTypes.bool,
  displayOptionalLabel: propTypes.bool,
};

TextAreaLabel.displayName = 'TextAreaLabel';
