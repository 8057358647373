import PropTypes from 'prop-types';
import React from 'react';

import { SocialNetworkWrapper } from './styles';

export const SocialNetworks = ({ maxWidth, type, horizontal, ...rest }) => {
  const isNew = type === 'new';
  const isVertical = horizontal === false;

  return (
    <SocialNetworkWrapper maxWidth={maxWidth} horizontal={horizontal} {...rest}>
      <li>
        <a href="https://www.instagram.com/credpago/" target="_blank" rel="noreferrer" title="Instagram">
          {isNew ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.4 42.4">
              <path
                d="M31 0H11.4C5.1 0 0 5.1 0 11.4V31c0 6.3 5.1 11.4 11.4 11.4H31c6.3 0 11.4-5.1 11.4-11.4V11.4C42.4 5.1 37.3 0 31 0zm8.2 31c0 4.5-3.7 8.2-8.2 8.2H11.4c-4.5 0-8.2-3.7-8.2-8.2V11.4c0-4.5 3.7-8.2 8.2-8.2H31c4.5 0 8.2 3.7 8.2 8.2V31zm-18-19.3c-5.7 0-10.3 4.6-10.3 10.3 0 5.7 4.6 10.3 10.3 10.3S31.5 27.6 31.5 22c0-5.7-4.6-10.3-10.3-10.3zm0 17.3c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM32 7.5c-1.6 0-2.9 1.3-2.9 2.9 0 1.6 1.3 2.9 2.9 2.9 1.6 0 2.9-1.3 2.9-2.9 0-1.6-1.3-2.9-2.9-2.9z"
                fill="#113c64"
              />
            </svg>
          ) : type === 'blue' ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.44 42.44">
              <g data-name="Layer 2">
                <g data-name="Layer 1">
                  <path
                    d="M39.63 6.91l-3.57-3.65-4.57-2H20.68c-.87.24-12.19 1.47-12.56 1.47S3.26 6.66 3.26 6.66L1.6 11.71s0 13.17.12 13.53.74 5.67.74 5.67l.13 4.46 4.3 3.81 4.25 1.94s12.73-.12 13.72-.37 7.14-.62 7.14-.62l5.17-1.73 2.46-3 .61-6.93zm-18.42 24A8.93 8.93 0 1130.14 22a8.93 8.93 0 01-8.93 8.91z"
                    fill="#22c4f4"
                  />
                  <path
                    d="M31 0H11.42A11.43 11.43 0 000 11.42V31a11.43 11.43 0 0011.42 11.44H31A11.42 11.42 0 0042.44 31V11.42A11.43 11.43 0 0031 0zm8.15 31A8.16 8.16 0 0131 39.18H11.42A8.17 8.17 0 013.26 31V11.42a8.17 8.17 0 018.16-8.16H31a8.17 8.17 0 018.15 8.16zm-18-19.32A10.27 10.27 0 1031.49 22a10.29 10.29 0 00-10.27-10.29zm0 17.28a7 7 0 117-7A7 7 0 0121.22 29zM32 7.54a2.91 2.91 0 000 5.82 2.91 2.91 0 000-5.82z"
                    fill="#113d64"
                  />
                </g>
              </g>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                fill="#0bc1ed"
                d="M22.2 11.5h-8.3c-1.3 0-2.4 1.1-2.4 2.4v8.3c0 1.3 1.1 2.4 2.4 2.4h8.3c1.3 0 2.4-1.1 2.4-2.4v-8.3c0-1.3-1.1-2.4-2.4-2.4zM18 22.4c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3zm4.5-7.7c-.6 0-1-.5-1-1 0-.6.5-1 1-1 .6 0 1 .5 1 1s-.5 1-1 1z"
              />
              <path
                fill="#0bc1ed"
                d="M18 0C8.1 0 0 8.1 0 18s8.1 18 18 18 18-8 18-18S28 0 18 0zm8.4 22.2c0 2.3-1.9 4.2-4.2 4.2h-8.3c-2.3 0-4.2-1.9-4.2-4.2v-8.3c0-2.3 1.9-4.2 4.2-4.2h8.3c2.3 0 4.2 1.9 4.2 4.2v8.3z"
              />
              <path
                fill="#0bc1ed"
                d="M18 15.6c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z"
              />
            </svg>
          )}
          {isVertical && <span className="socialNetworkName">@credpago</span>}
        </a>
      </li>
      <li>
        <a href="https://twitter.com/credpago" target="_blank" rel="noreferrer" title="Twitter">
          {isNew ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 16.8">
              <path
                className="st0"
                fill="#192D4D"
                d="M7 16.7c-4 0-5.6-2.2-6.8-3.9 0 0-.1-.1-.1-.2-.1 0-.1-.1-.1-.1 0-.2.1-.4.2-.6.1-.1.1-.1.2-.1h.1c.1 0 .8-.3 1.6-.5C-.3 8-.4 3.6 1.9.3c.3-.3.7-.4 1.1-.1l.2.2c.9 1.7 2.3 3 4 3.8C7.6 1.8 9.8 0 12.3 0c1.5-.1 3 .6 3.9 1.8h2.3c.4 0 .7.3.7.7 0 .1 0 .3-.1.4l-1.6 2.5c-.2 6.9-4.3 11.3-10.5 11.3zm-5-3.9c1.1 1.6 3 2.6 5 2.5 5.5 0 9-3.8 9-10 0-.2 0-.3.1-.4l1-1.5h-1.3c-.2 0-.5-.1-.6-.3-.6-1-1.8-1.6-2.9-1.5-2-.1-3.7 1.4-3.7 3.4v.2c0 .5-.4.8-.8.8h-.1c-2.1-.6-3.9-2-5.2-3.8C1 5.1 1.6 8.7 3.9 11c.3.3.3.8 0 1.1-.1.1-.2.1-.3.2l-1.6.5z"
              />
            </svg>
          ) : type === 'blue' ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 16.8">
              <path
                className="st0"
                fill="#192D4D"
                d="M7 16.7c-4 0-5.6-2.2-6.8-3.9 0 0-.1-.1-.1-.2-.1 0-.1-.1-.1-.1 0-.2.1-.4.2-.6.1-.1.1-.1.2-.1h.1c.1 0 .8-.3 1.6-.5C-.3 8-.4 3.6 1.9.3c.3-.3.7-.4 1.1-.1l.2.2c.9 1.7 2.3 3 4 3.8C7.6 1.8 9.8 0 12.3 0c1.5-.1 3 .6 3.9 1.8h2.3c.4 0 .7.3.7.7 0 .1 0 .3-.1.4l-1.6 2.5c-.2 6.9-4.3 11.3-10.5 11.3zm-5-3.9c1.1 1.6 3 2.6 5 2.5 5.5 0 9-3.8 9-10 0-.2 0-.3.1-.4l1-1.5h-1.3c-.2 0-.5-.1-.6-.3-.6-1-1.8-1.6-2.9-1.5-2-.1-3.7 1.4-3.7 3.4v.2c0 .5-.4.8-.8.8h-.1c-2.1-.6-3.9-2-5.2-3.8C1 5.1 1.6 8.7 3.9 11c.3.3.3.8 0 1.1-.1.1-.2.1-.3.2l-1.6.5z"
              />
              <path
                className="st1"
                fill="#1CB8EC"
                d="M2.2 12.834c1.1 1.6 3 2.6 5 2.5 5.5 0 9-3.8 9-10v-.2c0-.1.1-.1.1-.2l1-1.5H16c-.2 0-.5-.1-.6-.3-.6-1-1.8-1.6-2.9-1.5-2-.1-3.6 1.5-3.7 3.5v.2c0 .4-.4.7-.8.7h-.1c-2.1-.7-3.9-2-5.1-3.8-1.5 2.9-.9 6.5 1.4 8.8.3.3.3.8 0 1.1-.1.1-.2.1-.3.2l-1.7.5z"
              />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                d="M18 0C8.1 0 0 8.1 0 18c0 10 8.1 18 18 18s18-8 18-18c0-9.9-8-18-18-18zm7.7 15.1c0 .1-.1.1-.1.2v.2c0 6.2-3.5 10-9 10-2 .1-3.9-.8-5-2.5l1.7-.6c.1 0 .2-.1.3-.2.3-.3.3-.8 0-1.1-2.3-2.3-2.9-5.9-1.4-8.8 1.2 1.8 3 3.2 5.1 3.8h.1c.4 0 .7-.3.8-.7v-.2c.1-2 1.7-3.6 3.7-3.5 1.2-.1 2.3.5 2.9 1.5.1.2.4.3.6.3h1.3l-1 1.6z"
                fill="#0bc1ed"
              />
            </svg>
          )}
          {isVertical && <span className="socialNetworkName">/credpago</span>}
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/credpago.oficial/" target="_blank" rel="noreferrer" title="Facebook">
          {isNew ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.4 42.4">
              <path
                d="M31 0H11.4C5.1 0 0 5.1 0 11.4V31c0 6.3 5.1 11.4 11.4 11.4h5.3c.1 0 .2-.1.3-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1 0 .1-.1.2-.1.1-.1.2-.2.3-.5.1-.2.1-.5.1-.7v-.1c.1-1.3.1-4.4 0-12.4 0-.9-.7-1.6-1.6-1.6H13v-3.9h3c.9 0 1.6-.7 1.6-1.6v-3.3-.1s-.1-3.4 1.7-5.3c1-1 2.3-1.5 4-1.5H29v3.9h-5.8c-.9 0-1.6.7-1.6 1.6v4.7c0 .9.7 1.6 1.6 1.6H29l-.9 3.8h-4.5c-.9 0-1.6.7-1.6 1.6v12.5c0 .9.7 1.6 1.6 1.6H31c6.3 0 11.4-5.1 11.4-11.4V11.4C42.4 5.1 37.3 0 31 0zm8.2 31c0 4.5-3.7 8.2-8.2 8.2h-5.8V30h4.1c.8 0 1.4-.5 1.6-1.3l1.7-7.1c.1-.5 0-1-.3-1.4-.3-.4-.8-.6-1.3-.6h-6.2v-1.4h5.8c.9 0 1.6-.7 1.6-1.6V9.3c0-.9-.7-1.6-1.6-1.6h-7.2c-2.7 0-4.8.8-6.4 2.5-2.7 2.9-2.6 7.1-2.6 7.7v1.7h-3c-.9 0-1.6.7-1.6 1.6v7.2c0 .9.7 1.6 1.6 1.6h3.4c0 3.6.1 7.2 0 9.2h-3.4c-4.5 0-8.2-3.7-8.2-8.2V11.4c0-4.5 3.7-8.2 8.2-8.2H31c4.5 0 8.2 3.7 8.2 8.2V31z"
                fill="#113c64"
              />
            </svg>
          ) : type === 'blue' ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.44 42.44">
              <g data-name="Layer 2">
                <g data-name="Layer 1">
                  <path
                    d="M16.17 40.13V29h-4.51v-7.82l4.51-.18 1.27-9.25 5.41-2.65 7.22-.18.72 4.68-2 2.9-5.05-.18-.35 4.68 7.4.35-2 6.13h-6.54l1.68 13.68h10.29l5-5.75s1.89-5.22 1.89-6.38V9.79l-2.93-6.08-6-2.46h-9.69s-11 .29-11.73.53-5 1.48-5 1.48l-4.2 7.19v13.41l1.7 12.26s4.61 3.7 5.16 4 7.75.01 7.75.01z"
                    fill="#22c4f4"
                  />
                  <path
                    d="M31 0H11.42A11.43 11.43 0 000 11.42V31a11.43 11.43 0 0011.42 11.44h5a1.66 1.66 0 00.32 0 1.4 1.4 0 00.29-.09 1.35 1.35 0 00.26-.15l.1-.07.16-.14a1.12 1.12 0 00.33-.49 1.59 1.59 0 00.14-.66v-.08c.13-1.33.1-4.41 0-12.36a1.63 1.63 0 00-1.63-1.62H13v-3.9h3a1.63 1.63 0 001.63-1.63V17.8s-.14-3.37 1.73-5.33a5.24 5.24 0 014-1.49H29v3.92h-5.78a1.63 1.63 0 00-1.63 1.63v4.69a1.63 1.63 0 001.63 1.63H29l-.91 3.83h-4.46A1.64 1.64 0 0022 28.32v12.49a1.63 1.63 0 001.64 1.63h7.41A11.42 11.42 0 0042.44 31V11.42A11.43 11.43 0 0031 0zm8.15 31A8.16 8.16 0 0131 39.18h-5.74V30h4.12A1.63 1.63 0 0031 28.7l1.7-7.1a1.64 1.64 0 00-1.59-2h-6.26v-1.44h5.82a1.63 1.63 0 001.63-1.63V9.35a1.63 1.63 0 00-1.63-1.63h-7.25A8.42 8.42 0 0017 10.23c-2.71 2.86-2.64 7.13-2.61 7.69v1.67h-3a1.63 1.63 0 00-1.64 1.63v7.16A1.63 1.63 0 0011.37 30h3.39c0 3.58.06 7.21 0 9.16h-3.34A8.17 8.17 0 013.26 31V11.42a8.17 8.17 0 018.16-8.16H31a8.17 8.17 0 018.15 8.16z"
                    fill="#113d64"
                  />
                </g>
              </g>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                d="M18 0C8.1 0 0 8.1 0 18c0 10 8.1 18 18 18 10 0 18-8.1 18-18S28 0 18 0zm3.3 13.5h-1.8c-.3 0-.7.1-.7.8V16h2.5l-.3 2.7h-2.2v8.1h-3.3v-8.1h-1.6v-2.8h1.6v-1.8c0-1.3.6-3.4 3.4-3.4h2.5v2.8z"
                fill="#0bc1ed"
              />
            </svg>
          )}
          {isVertical && <span className="socialNetworkName">/credpago</span>}
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UCT4NcSoRFbgoiNJbXOIS0Ig"
          target="_blank"
          rel="noreferrer"
          title="Youtube"
        >
          {isNew ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.8 38.9">
              <path
                d="M45.8 6.8c-.6-2.7-2.5-4.9-5-5.7C37.4 0 24.8 0 23.4 0 22 0 9.4 0 5.9 1.1c-2.4.8-4.3 2.9-5 5.7C0 10.9 0 19.1 0 19.4s0 8.6.9 12.7c.6 2.7 2.5 4.9 5 5.7 3.5 1.1 16.1 1.1 17.5 1.1 1.4 0 14 0 17.5-1.1 2.4-.8 4.3-2.9 5-5.7.9-4.1.9-12.3.9-12.6s0-8.6-1-12.7zm-3.1 24.5c-.4 1.6-1.4 2.9-2.7 3.3-2.6.8-12.8 1-16.5 1-3.7 0-13.9-.2-16.5-1-1.3-.4-2.4-1.7-2.7-3.3-.9-3.7-.9-11.8-.9-11.9s0-8.1.9-11.9C4.7 5.9 5.7 4.6 7 4.2c2.6-.8 12.8-1 16.5-1 3.7 0 13.9.2 16.5 1 1.3.4 2.4 1.7 2.7 3.3.9 3.7.9 11.8.9 11.9s-.1 8.2-.9 11.9zM31.2 18l-11.3-6.5c-.5-.3-1.1-.3-1.6 0s-.8.8-.8 1.4V26c0 .6.3 1.1.8 1.4.3.1.5.2.8.2s.6-.1.8-.2l11.3-6.5c.5-.3.8-.8.8-1.4 0-.6-.3-1.2-.8-1.5zm-10.5 5.1v-7.4l6.4 3.7-6.4 3.7z"
                fill="#113c64"
              />
            </svg>
          ) : type === 'blue' ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.8 38.88">
              <g data-name="Layer 2">
                <g data-name="Layer 1">
                  <path
                    d="M43.53 6.64c.37-.3-3.14-4-3.14-4L34.74 2h-15l-15 1.27-3 5.65S1 22.51 1 23.14 2.61 31 2.71 31.66a29.41 29.41 0 002 4l7.7 1.24h24.77l4.38-1.24 3-4.15L46.14 21S43.16 6.93 43.53 6.64zM19.26 24.85s.2-9.26 0-9.94H23s6 4.19 6.62 4.51-10.36 5.43-10.36 5.43z"
                    fill="#22c4f4"
                  />
                  <path
                    d="M45.85 6.83a7.65 7.65 0 00-5-5.7C37.36 0 24.82 0 23.4 0S9.44 0 5.9 1.13A7.63 7.63 0 001 6.83c-1 4.1-1 12.26-1 12.61S0 28 1 32.05a7.62 7.62 0 005 5.7c3.54 1.1 16.08 1.13 17.5 1.13s14 0 17.49-1.13a7.66 7.66 0 005-5.7c.93-4.1.95-12.26.95-12.61s-.15-8.51-1.09-12.61zm-3.18 24.49a4.38 4.38 0 01-2.75 3.31c-2.59.81-12.8 1-16.52 1s-13.93-.18-16.52-1a4.36 4.36 0 01-2.75-3.31c-.85-3.74-.87-11.8-.87-11.88s0-8.14.87-11.88a4.38 4.38 0 012.75-3.31c2.59-.81 12.8-1 16.52-1s13.93.18 16.52 1a4.36 4.36 0 012.74 3.31c.86 3.74.87 11.8.87 11.88s-.01 8.14-.86 11.88zM31.17 18l-11.3-6.5a1.61 1.61 0 00-1.64 0 1.63 1.63 0 00-.81 1.41V26a1.63 1.63 0 002.45 1.41l11.3-6.53a1.62 1.62 0 000-2.82zm-10.49 5.14v-7.4l6.41 3.7z"
                    fill="#113d64"
                  />
                </g>
              </g>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.3 36.3">
              <path
                fill="#0bc1ed"
                d="M21.2 17.5L17 15.3c-.2-.1-.4-.1-.6 0-.2.1-.3.3-.3.6v4.4c0 .2.1.4.3.6.1.1.2.1.3.1.1 0 .2 0 .3-.1l4.2-2.2c.2-.1.3-.3.3-.6.1-.3-.1-.5-.3-.6z"
              />
              <path
                fill="#0bc1ed"
                d="M18.2 0C8.1 0 0 8.1 0 18.2c0 10 8.1 18.2 18.2 18.2 10 0 18.2-8.1 18.2-18.2C36.3 8.1 28.2 0 18.2 0zm10.2 19.9c0 2.2-.3 3.5-1.2 4.3-.8.8-2 1.1-4.3 1.1h-9.3c-4.6 0-5.4-1.8-5.4-5.3v-3.5c0-1.8 0-3.1.7-4 .7-.9 2.1-1.3 4.7-1.3H23c2.6 0 3.9.4 4.6 1.2.7.9.8 2.2.8 4.1v3.4z"
              />
            </svg>
          )}
          {isVertical && <span className="socialNetworkName">/credpagocanal</span>}
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/credpago/" target="_blank" rel="noreferrer" title="LinkedIn">
          {isNew ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.4 42.4">
              <path
                d="M31 0H11.4C5.1 0 0 5.1 0 11.4V31c0 6.3 5.1 11.4 11.4 11.4H31c6.3 0 11.4-5.1 11.4-11.4V11.4C42.4 5.1 37.3 0 31 0zm8.2 31c0 4.5-3.7 8.2-8.2 8.2H11.4c-4.5 0-8.2-3.7-8.2-8.2V11.4c0-4.5 3.7-8.2 8.2-8.2H31c4.5 0 8.2 3.7 8.2 8.2V31zM16 19.5h-4.3c-.7 0-1.4.6-1.4 1.4V34c0 .7.6 1.4 1.4 1.4H16c.7 0 1.4-.6 1.4-1.4V20.9c0-.8-.6-1.4-1.4-1.4zm-1.3 13.2h-1.6V22.2h1.6v10.5zm16.9-11.8c-2-1.4-4.3-1.4-6-.7l-.1-.1c-.3-.4-.8-.6-1.3-.6h-3.8c-.7 0-1.4.6-1.4 1.4V34c0 .7.6 1.4 1.4 1.4h4.3c.7 0 1.4-.6 1.4-1.4v-6.4c0-.2.1-.6.2-.7 0 0 .1-.1.4-.1.2 0 .7 0 .7.7V34c0 .7.6 1.4 1.4 1.4h4.3c.7 0 1.4-.6 1.4-1.4v-8c-.1-.5-.1-3.1-2.9-5.1zm.1 5v6.8h-1.6v-5.2c0-.2 0-1.5-1-2.5-.4-.4-1.2-.9-2.4-.9s-2 .5-2.4.9c-1 1-1 2.5-1 2.6v5h-1.6V22.2h1.8c.1.3.4.5.6.7.5.3 1.2.4 1.8.1 1.1-.6 2.7-.8 4.1.2 1.8 1.1 1.7 2.7 1.7 2.7zM16 10.8h-4.3c-.7 0-1.4.6-1.4 1.4v4.4c0 .7.6 1.4 1.4 1.4H16c.7 0 1.4-.6 1.4-1.4v-4.4c0-.7-.6-1.4-1.4-1.4zm-1.3 4.5h-1.6v-1.7h1.6v1.7z"
                fill="#113c64"
              />
            </svg>
          ) : type === 'blue' ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.44 42.44">
              <g data-name="Layer 2">
                <g data-name="Layer 1">
                  <path
                    d="M41.3 22.22l-.74-13.83-3.82-4.68-3.2-1.93-6.84-.53H13.89L7.46 2.6l-4.2 3-2.45 6.11L2 21.06.81 30.91l2.45 5.53 4.32 3.69 7.11 1 10-1h8l4.56-1 3.07-3.81 1.23-5.08zM17.4 34a1.34 1.34 0 01-1.35 1.35h-4.32A1.35 1.35 0 0110.37 34V20.87a1.36 1.36 0 011.36-1.36h4.32a1.35 1.35 0 011.35 1.36zm0-17.38A1.35 1.35 0 0116.05 18h-4.32a1.36 1.36 0 01-1.36-1.36V12.2a1.36 1.36 0 011.36-1.35h4.32a1.35 1.35 0 011.35 1.35zm17 9.39v8a1.35 1.35 0 01-1.36 1.35h-4.33A1.34 1.34 0 0127.36 34v-6.55c0-.71-.47-.71-.71-.71s-.39.09-.42.12a1.26 1.26 0 00-.22.73V34a1.35 1.35 0 01-1.36 1.35h-4.32A1.35 1.35 0 0119 34V20.87a1.36 1.36 0 011.35-1.36h3.85a1.79 1.79 0 011.3.56l.1.11a6.58 6.58 0 016 .74A6.14 6.14 0 0134.42 26z"
                    fill="#22c4f4"
                  />
                  <path
                    d="M31 0H11.42A11.43 11.43 0 000 11.42V31a11.43 11.43 0 0011.42 11.44H31A11.42 11.42 0 0042.44 31V11.42A11.43 11.43 0 0031 0zm8.15 31A8.16 8.16 0 0131 39.18H11.42A8.17 8.17 0 013.26 31V11.42a8.17 8.17 0 018.16-8.16H31a8.17 8.17 0 018.15 8.16zm-23.1-11.49h-4.32a1.36 1.36 0 00-1.36 1.36V34a1.35 1.35 0 001.36 1.35h4.32A1.34 1.34 0 0017.4 34V20.87a1.35 1.35 0 00-1.35-1.36zm-1.36 13.16h-1.61V22.22h1.61zM31.6 20.92a6.58 6.58 0 00-6-.74l-.1-.11a1.79 1.79 0 00-1.3-.56h-3.87A1.36 1.36 0 0019 20.87V34a1.35 1.35 0 001.35 1.35h4.32A1.35 1.35 0 0026 34v-6.41a1.26 1.26 0 01.22-.73s.12-.12.42-.12.69 0 .71.71V34a1.34 1.34 0 001.35 1.35h4.35A1.35 1.35 0 0034.42 34v-8a6.14 6.14 0 00-2.82-5.08zm.11 5v6.78h-1.64v-5.24a3.73 3.73 0 00-1-2.49 3.3 3.3 0 00-2.42-.97 3.2 3.2 0 00-2.37.95 3.93 3.93 0 00-1 2.64v5.05h-1.59V22.22h1.79a1.87 1.87 0 00.63.68 1.8 1.8 0 001.81.07 4 4 0 014.14.18 3.42 3.42 0 011.65 2.74zm-15.66-15h-4.32a1.36 1.36 0 00-1.36 1.35v4.44A1.36 1.36 0 0011.73 18h4.32a1.35 1.35 0 001.35-1.36V12.2a1.35 1.35 0 00-1.35-1.35zm-1.36 4.44h-1.61v-1.8h1.61z"
                    fill="#113d64"
                  />
                </g>
              </g>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                fill="#0bc1ed"
                d="M19.6 16.2zM18 0C8.1 0 0 8.1 0 18c0 10 8.1 18 18 18s18-8 18-18c0-9.9-8-18-18-18zm-3.3 24.4h-3.2v-9.5h3.2v9.5zm-1.6-10.9c-1.1 0-1.8-.7-1.8-1.6 0-.9.7-1.6 1.8-1.6s1.8.7 1.8 1.6c0 .9-.7 1.6-1.8 1.6zm13 10.9H23v-5.1c0-1.3-.5-2.2-1.6-2.2-.9 0-1.4.6-1.6 1.2-.1.2-.1.5-.1.8v5.3h-3.2v-9.5h3.2v1.4c.4-.6 1.2-1.6 2.9-1.6 2.1 0 3.7 1.4 3.7 4.3v5.4z"
              />
            </svg>
          )}
          {isVertical && <span className="socialNetworkName">/credpago</span>}
        </a>
      </li>
    </SocialNetworkWrapper>
  );
};

SocialNetworks.defaultProps = {
  type: 'default',
  horizontal: true,
};

SocialNetworks.propTypes = {
  type: PropTypes.oneOf(['default', 'new', 'blue']),
  horizontal: PropTypes.bool,
};
