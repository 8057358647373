import { createGlobalStyle } from 'styled-components';

export const globals = {
  siteMaxWidth: '1420px',
  sitePaddingSides: '20px',
  sizes: {
    small: '32px',
    default: '48px',
    large: '128px',
  },
  fonts: {
    default: "'Roboto', Arial, sans-serif",
    titles: "'Uni Neue', Arial, sans-serif",
  },
  colors: {
    brandDarkBlue: '#001c4a',
    brandLightBlue: '#00c6ff',
    lightButton: '#d9f2fc',
    gray: '#7D7D7D',
    red: 'red',
    success: '#32BA7C',
  },
  mediaqueries: {
    xs: 'screen and (max-width: 575px)',
    sm: 'screen and (min-width:576px) and (max-width: 767px)',
    md: 'screen and (min-width:768px) and (max-width: 991px)',
    lg: 'screen and (min-width: 992px) and (max-width: 1399px)',
    xl: 'screen and (min-width: 1400px)',
  },
};

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${globals.fonts.default};
  }

  body {
    font-size: 15px;
    font-family: ${globals.fonts.default};
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
`;
