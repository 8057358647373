import styled from 'styled-components';

import { globals } from '../globals';

const { fonts } = globals;

const typeColors = {
  error: {
    background: '#D60000',
  },
  success: {
    background: '#39BA7C',
  },
};

export const AlertContainer = styled.div`
  font-family: ${fonts.default};
  background: ${({ type }) => typeColors[type || 'error'].background};
  padding: 21px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 18px;

  > p {
    margin: 0 0 0 21px;
  }
`;
