import { darken, lighten } from 'polished';
import styled from 'styled-components';

import { globals } from '../globals';

const { colors, fonts, mediaqueries } = globals;
const { xs, sm } = mediaqueries;

export const TabsWrapper = styled.section`
  background: white;
  display: block;
  font-family: ${fonts.default};

  > ol {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    @media ${xs}, ${sm} {
      overflow-x: auto;
    }

    li {
      display: inline-block;
      flex-grow: 1;
      text-align: center;
      outline: none;

      button {
        width: 100%;
        text-align: center;
        cursor: pointer;
        display: block;
        background: none;
        border: 0;
        color: ${colors.brandDarkBlue};
        padding: 12px 13px;
        font-family: ${fonts.titles};
        font-weight: 600;
        font-size: 1.1em;
        outline: none;

        @media ${xs}, ${sm} {
          padding: 12px 8px;
          font-size: 0.9em;
        }

        &:hover {
          color: ${lighten(0.15, colors.brandDarkBlue)};
        }

        &:active {
          color: ${darken(0.2, colors.brandLightBlue)};
          box-shadow: 0 0 0 1px ${darken(0.2, colors.brandLightBlue)};
        }

        &:focus:not(:focus-visible) {
          outline: none;
          box-shadow: 0 0 0 1px ${colors.brandLightBlue};
        }

        &:focus-visible {
          outline: none;
        }
      }

      &.is-active {
        button {
          font-weight: bold;
          box-shadow: inset 0 -2px 0 0 ${colors.brandDarkBlue};

          &:focus:not(:focus-visible) {
            outline: none;
            box-shadow: inset 0 -2px 0 0 ${colors.brandDarkBlue};
          }

          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }

  .tab-content {
    line-height: 1.5;
  }
`;

export const TabPanelsWrapper = styled.div`
  border: none;
  padding: 20px;

  @media ${xs}, ${sm} {
    padding: 20px 10px;
  }
`;
