import propTypes from 'prop-types';
import React from 'react';
import { MdCheck, MdError } from 'react-icons/md';

import { AlertContainer } from './styles';

const alertTypes = {
  error: MdError,
  success: MdCheck,
};

export const Alert = ({ message, type }) => {
  const Icon = alertTypes[type || 'error'];

  return (
    <AlertContainer type={type}>
      <Icon size={32} />
      <p>{message}</p>
    </AlertContainer>
  );
};

Alert.propTypes = {
  message: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
};
