import React from 'react';

import { Col as StyledCol, Row as GridContainer } from './styles';

/**
 * Primary UI component for grids <br />
 * ```import UI from 'front-credpago'``` <br />
 * ```<UI.Grid />```
 */
export const Row = (props) => {
  const { children, type, ...rest } = props;

  return (
    <GridContainer type={type} className="grid-row" {...rest}>
      {children}
    </GridContainer>
  );
};

export const Col = (props) => {
  const { children, className, responsive, ...rest } = props;
  return (
    <StyledCol className={`col ${className}`} responsive={responsive} {...rest}>
      <div className="col-inner">{children}</div>
    </StyledCol>
  );
};
