import { Radio as RadioAntd } from 'antd';
import React from 'react';

import * as S from './styles';

export const Radio = ({ onChange, value, radios, styleGroup, styleRadio }) => (
  <RadioAntd.Group onChange={onChange} value={value} style={styleGroup}>
    {radios.map((radio) => (
      <S.Radio key={radio.value} value={radio.value} style={styleRadio}>
        {radio.label}
      </S.Radio>
    ))}
  </RadioAntd.Group>
);
