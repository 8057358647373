import { darken, lighten } from 'polished';
import styled from 'styled-components';

import { globals } from '../globals';

const { colors, sizes, fonts } = globals;

export const Button = styled.button`
  border-radius: 15px;
  font-family: ${fonts.default};
  font-weight: bold;
  border: 0;
  padding: 0;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: all 0.2s ease;

  &[disabled] {
    opacity: 0.9;
    pointer-events: none;
  }

  /*
    Button Types
  */

  &.button--primary {
    background: ${colors.brandDarkBlue};
    color: white;

    &:hover {
      background: ${lighten(0.09, colors.brandDarkBlue)};
    }

    &:active {
      background: ${darken(0.1, colors.brandDarkBlue)};
    }

    &:focus:not(:focus-visible) {
      box-shadow: 0 0 0 2px ${colors.brandLightBlue};
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px ${colors.brandLightBlue};
    }

    .icon-wrapper svg {
      * {
        fill: white;
      }
    }
  }

  &.button--success {
    background: ${colors.success};
    color: white;

    &:hover {
      background: ${lighten(0.09, colors.success)};
    }

    &:active {
      background: ${darken(0.1, colors.success)};
    }

    &:focus:not(:focus-visible) {
      box-shadow: 0 0 0 2px ${colors.success};
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px ${colors.success};
    }

    .icon-wrapper svg {
      * {
        fill: white;
      }
    }
  }

  &.button--secondary {
    background: transparent;
    color: ${colors.brandDarkBlue};
    box-shadow: inset 0 0 0 1px ${colors.brandDarkBlue};

    &:hover {
      box-shadow: inset 0 0 0 1px ${lighten(0.12, colors.brandDarkBlue)};
      color: ${lighten(0.12, colors.brandDarkBlue)};
    }

    &:active {
      box-shadow: inset 0 0 0 1px ${darken(0.2, colors.brandDarkBlue)};
      color: ${darken(0.2, colors.brandDarkBlue)};
    }

    &.button--is-inverted {
      color: white;
      box-shadow: inset 0 0 0 1px white;

      &:hover {
        color: ${colors.brandLightBlue};
        box-shadow: inset 0 0 0 1px ${colors.brandLightBlue};
      }
    }

    &:focus:not(:focus-visible) {
      box-shadow:
        inset 0 0 0 1px ${colors.brandDarkBlue},
        0 0 0 2px ${colors.brandLightBlue};
    }

    &:focus-visible {
      box-shadow:
        inset 0 0 0 1px ${colors.brandDarkBlue},
        0 0 0 2px ${colors.brandLightBlue};
    }

    .icon-wrapper svg {
      * {
        fill: #333;
      }
    }
  }

  &.button--default {
    background: ${colors.brandLightBlue};
    color: white;

    &:hover {
      background: ${lighten(0.05, colors.brandLightBlue)};
    }

    &:active {
      background: ${darken(0.1, colors.brandLightBlue)};
    }

    &:focus:not(:focus-visible) {
      box-shadow: 0 0 0 2px ${colors.brandLightBlue};
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px ${colors.brandLightBlue};
    }

    .icon-wrapper svg {
      * {
        fill: #333;
      }
    }
  }

  &.button--light {
    background: ${colors.lightButton};

    &:hover {
      background: ${lighten(0.02, colors.lightButton)};
    }

    &:active {
      background: ${darken(0.04, colors.lightButton)};
    }

    &:focus:not(:focus-visible) {
      box-shadow: 0 0 0 2px ${colors.brandLightBlue};
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px ${colors.brandLightBlue};
    }

    .icon-wrapper svg {
      * {
        fill: #333;
      }
    }
  }

  /*
    Button Sizes
  */

  &.button--small {
    font-size: 0.75em;
    min-height: ${sizes.small};
    padding-left: 12px;
    padding-right: 12px;

    .loader {
      left: 0;

      svg {
        width: 15px;
      }
    }

    &.button--is-loading {
      padding-left: 38px;
    }
  }

  &.button--medium {
    font-size: 1em;
    min-height: ${sizes.default};
    line-height: 1;
    padding: 10px 20px;

    .loader svg {
      width: 18px;
    }

    &.button--is-loading {
      padding-left: 42px;
    }
  }

  &.button--large {
    font-size: 1.1em;
    min-height: ${sizes.default};
    padding-left: 35px;
    padding-right: 35px;

    &.button--is-loading {
      padding-left: 51px;
    }
  }

  /*
    Button Mods
  */

  &.button--icon-with-text {
    .icon-wrapper + span {
      margin-left: 0.6em;
    }
  }

  &.button--blocked {
    width: 100%;
    display: block;
    text-align: center;

    &.button--is-loading {
      display: inline-flex;
      text-align: center;
      justify-content: center;
      align-items: center;

      .loader {
        position: static;
        top: 0;
        transform: translate3d(-20px, 1px, 0);
      }
    }
  }

  .icon-wrapper {
    svg {
      width: 16px;
      height: auto;
      display: inline-block;
    }
  }

  .loader {
    width: ${(props) => (props.loading === 'true' ? '26px' : '0px')};
    height: 26px;
    display: block;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    transition: all 0.2s;
    opacity: ${(props) => (props.loading === 'true' ? '1' : '0')};

    svg {
      -webkit-animation: rotate 2s linear infinite;
      animation: rotate 2s linear infinite;
      z-index: 2;
      position: absolute;
      margin: 0;
      width: 23px;
      height: 23px;
    }

    .path {
      stroke: #cccccc;
      stroke-linecap: round;
      -webkit-animation: dash 1.5s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
