import {
  AccordionItem,
  Alert,
  Button,
  Col,
  Description,
  globals,
  GlobalStyle,
  Header,
  InputLabel,
  Radio,
  Row,
  SelectInput,
  SocialNetworks,
  Tab,
  Tabs,
  TextAreaLabel,
  Title,
} from './components';

export default {
  AccordionItem,
  Alert,
  Button,
  globals,
  GlobalStyle,
  Row,
  Col,
  Header,
  InputLabel,
  Radio,
  SelectInput,
  SocialNetworks,
  Tab,
  Tabs,
  TextAreaLabel,
  Title,
  Description,
};
