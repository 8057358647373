import PropTypes from 'prop-types';
import React from 'react';

import { Button as StyledButton } from './styles';
//import './button.css';

/**
 * Primary UI component for user interaction <br />
 * ```import UI from 'front-credpago'``` <br />
 * ```<UI.Button />```
 */
export const Button = ({
  ariaLabel,
  primary,
  size,
  children,
  loading,
  type,
  htmlType,
  label,
  inverted,
  icon,
  blocked,
  className,
  ...props
}) => {
  const mode = primary ? 'storybook-button--primary' : 'storybook-button--secondary';
  const isInverted = inverted ? 'button--is-inverted' : '';
  const isBlocked = blocked ? 'button--blocked' : '';
  const isLoaded = loading ? 'button--is-loading' : 'button--not-loading';
  const hasText = Boolean(label || children);
  const iconClasses = icon ? (hasText ? 'button--icon-with-text' : 'button--icon-without-text') : '';

  return (
    <StyledButton
      aria-label={ariaLabel}
      type={htmlType || 'button'}
      className={[
        'button',
        `button--${size}`,
        `button--${type}`,
        mode,
        isBlocked,
        isLoaded,
        isInverted,
        iconClasses,
        className,
      ].join(' ')}
      loading={loading.toString()}
      {...props}
    >
      {loading && (
        <span className="loader">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
          </svg>
        </span>
      )}
      {icon && <span className="icon-wrapper">{icon}</span>}
      <span>{children || label}</span>
    </StyledButton>
  );
};

Button.propTypes = {
  ariaLabel: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button types
   */
  type: PropTypes.oneOf(['default', 'success', 'primary', 'secondary', 'link', 'light']),
  /**
   * HTML Type
   */
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  /**
   * Blocked
   */
  blocked: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Loading
   */
  loading: PropTypes.bool,
  /**
   * Inverted
   */
  inverted: PropTypes.bool,
};

Button.defaultProps = {
  ariaLabel: 'Button',
  type: 'primary',
  size: 'medium',
  htmlType: 'button',
  onClick: undefined,
  blocked: false,
  loading: false,
  inverted: false,
};
