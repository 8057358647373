import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  InputField,
  InputFieldMasked,
  InputFieldWrapper,
  InputLabelWrapper,
  Label as CustomLabel,
  StyledAutosuggest,
} from './styles';

const parse = require('autosuggest-highlight/parse');

/**
 * Primary UI component for form interaction <br />
 * ```import { InputLabel } from 'front-credpago/externals'```
 */
export const InputLabel = React.forwardRef(
  (
    {
      id,
      value,
      label,
      required,
      name,
      type,
      htmlType,
      loading,
      mask,
      maskChar,
      onChange,
      onComplete,
      onBlur,
      displayOptionalLabel,
      placeholder,
      suggestions,
      disabled,
      getSuggestionsFn,
      onSuggestionSelected,
      renderSuggestionPropSearch,
      error,
      tabindex,
      autoComplete,
    },
    ref,
  ) => {
    const customId = id || `input-label--${label}`;
    const [currentValueSuggestion, setCurrentValueSuggestion] = useState('');
    const [currentSuggestions, setCurrentSuggestions] = useState([]);

    const { hasError } = error;

    const convertPatternsToRegex = (maskType) => {
      let pattern = maskType.replace(new RegExp('9', 'g'), '\\d');
      pattern = pattern.replace(new RegExp('a', 'g'), '\\w');
      pattern = pattern.replace(new RegExp('\\*', 'g'), '[a-zA-Z0-9]');
      return new RegExp(pattern);
    };

    const onChangeWithOnCompleteTrigger = (e) => {
      let hasMatch = false;
      let { value } = e.target;

      if (mask) {
        const regex = convertPatternsToRegex(mask);
        hasMatch = regex.exec(e.target.value);
      }
      onChange(mask ? value.toUpperCase() : value);

      if (mask && hasMatch && onComplete) {
        // retorna o valor do input
        onComplete(mask ? value.toUpperCase() : value);
      }
    };

    // Autosuggest fns
    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      if (getSuggestionsFn) {
        return getSuggestionsFn(value);
      }

      return inputLength === 0
        ? []
        : suggestions.filter((item) => {
            const parameter = renderSuggestionPropSearch
              ? getPropByString(item, renderSuggestionPropSearch)
              : item.name;
            const regex = new RegExp(inputValue.toLowerCase());
            return regex.exec(parameter.toLowerCase());
          });
    };

    const onSuggestionsFetchRequested = (data) => {
      const { value } = data;
      setCurrentSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
      setCurrentSuggestions([]);
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = (suggestion) => {
      const parameter = renderSuggestionPropSearch
        ? getPropByString(suggestion, renderSuggestionPropSearch)
        : suggestion.name;
      return parameter;
    };

    function customMatch(text, query) {
      const results = [];
      const trimmedQuery = query.trim().toLowerCase();
      const textLower = text.toLowerCase();
      const queryLength = trimmedQuery.length;
      let indexOf = textLower.indexOf(trimmedQuery);
      while (indexOf > -1) {
        results.push([indexOf, indexOf + queryLength]);
        indexOf = textLower.indexOf(query, indexOf + queryLength);
      }
      return results;
    }

    // Use your imagination to render suggestions.
    function renderSuggestion(suggestion, { query }) {
      const parameter = renderSuggestionPropSearch
        ? getPropByString(suggestion, renderSuggestionPropSearch)
        : suggestion.name.toLowerCase();
      const matches = customMatch(parameter, query);
      const parts = parse(parameter, matches);

      return (
        <span>
          {parts.map((part, index) => {
            const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      );
    }

    const getPropByString = (obj, propString) => {
      if (!propString) return obj;

      var prop,
        props = propString.split('.');

      for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
        prop = props[i];

        var candidate = obj[prop];
        if (candidate !== undefined) {
          obj = candidate;
        } else {
          break;
        }
      }
      return obj[props[i]];
    };

    const InputText = mask ? InputFieldMasked : InputField;

    const renderInput = () => {
      if (type === 'autosuggest') {
        return (
          <StyledAutosuggest
            suggestions={currentSuggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(event, suggestion) => {
              setCurrentValueSuggestion(suggestion.suggestionValue);
              if (onSuggestionSelected) {
                onSuggestionSelected(event, suggestion);
              }
            }}
            inputProps={{
              onChange: (e) => {
                setCurrentValueSuggestion(e.target.value);
                if (onChange) {
                  onChange(e);
                }
              },
              tabindex,
              hasError,
              onBlur,
              value: currentValueSuggestion,
              placeholder,
              name,
              id,
              className: 'input--autosuggest',
              ref,
              disabled,
            }}
          />
        );
      }

      return (
        <InputText
          type={htmlType}
          autoComplete={autoComplete}
          id={customId}
          placeholder={placeholder}
          onChange={onChangeWithOnCompleteTrigger}
          onBlur={onBlur}
          mask={mask}
          maskChar={maskChar}
          value={value}
          ref={ref}
          hasError={hasError}
          inputRef={ref}
          disabled={disabled}
          tabIndex={tabindex}
          name={name}
        />
      );
    };

    return (
      <InputLabelWrapper className="input-label">
        {label && (
          <CustomLabel hasError={hasError} htmlFor={customId}>
            {label}
            {required && <span className="required">*</span>}:{!required && displayOptionalLabel && <i> (opcional)</i>}
          </CustomLabel>
        )}
        <InputFieldWrapper loading={loading.toString()}>
          {renderInput()}
          {loading && (
            <div className="loader">
              <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
              </svg>
            </div>
          )}
        </InputFieldWrapper>
        {hasError && <p className="error__message">{error.message}</p>}
      </InputLabelWrapper>
    );
  },
);

InputLabel.propTypes = {
  type: PropTypes.string,
  htmlType: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  suggestions: PropTypes.array,
};

InputLabel.defaultProps = {
  type: 'text',
  htmlType: 'text',
  label: '',
  required: false,
  name: '',
  loading: false,
  maskChar: '_',
  onChange: (e) => e,
  onBlur: (e) => e,
  displayOptionalLabel: true,
  suggestions: [],
  getSuggestionsFn: null,
  renderSuggestionPropSearch: null,
  error: {},
  tabindex: 0,
};

InputLabel.displayName = 'InputLabel';
