import { fluidRange } from 'polished';
import styled from 'styled-components';

import { globals } from '../globals';

const { fonts, colors, mediaqueries } = globals;
const { md, lg, xl } = mediaqueries;

export const H1 = styled.h1`
  margin: 0;
  padding: 0;
  font-family: ${fonts.titles};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  line-height: 1.2;
  ${fluidRange({ prop: 'font-size', fromSize: '36px', toSize: '80px' })};
  color: ${colors.brandDarkBlue};

  @media ${md}, ${lg}, ${xl} {
    letter-spacing: -3px;
  }

  &.act-as-h2 {
    ${fluidRange({ prop: 'font-size', fromSize: '30px', toSize: '66px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h3 {
    font-size: 40px;
    ${fluidRange({ prop: 'font-size', fromSize: '24px', toSize: '51px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h4 {
    ${fluidRange({ prop: 'font-size', fromSize: '20px', toSize: '40px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  &.act-as-h5 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  &.act-as-h6 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};
  }

  .splitted-span {
    color: ${colors.brandLightBlue};
  }
`;

export const H2 = styled.h2`
  margin: 0;
  padding: 0;
  font-family: ${fonts.titles};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  line-height: 1.2;
  ${fluidRange({ prop: 'font-size', fromSize: '30px', toSize: '66px' })};
  color: ${colors.brandDarkBlue};

  @media ${md}, ${lg}, ${xl} {
    letter-spacing: -2px;
  }

  &.act-as-h1 {
    ${fluidRange({ prop: 'font-size', fromSize: '36px', toSize: '80px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -3px;
    }
  }

  &.act-as-h3 {
    font-size: 40px;
    ${fluidRange({ prop: 'font-size', fromSize: '24px', toSize: '51px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h4 {
    ${fluidRange({ prop: 'font-size', fromSize: '20px', toSize: '40px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  &.act-as-h5 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  &.act-as-h6 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};
  }

  .splitted-span {
    color: ${colors.brandLightBlue};
  }
`;

export const H3 = styled.h3`
  margin: 0;
  padding: 0;
  font-family: ${fonts.titles};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  line-height: 1.2;
  font-size: 40px;
  ${fluidRange({ prop: 'font-size', fromSize: '24px', toSize: '51px' })};
  color: ${colors.brandDarkBlue};

  @media ${md}, ${lg}, ${xl} {
    letter-spacing: -2px;
  }

  &.act-as-h1 {
    ${fluidRange({ prop: 'font-size', fromSize: '36px', toSize: '80px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -3px;
    }
  }

  &.act-as-h2 {
    ${fluidRange({ prop: 'font-size', fromSize: '30px', toSize: '66px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h4 {
    ${fluidRange({ prop: 'font-size', fromSize: '20px', toSize: '40px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  &.act-as-h5 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  &.act-as-h6 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};
  }

  .splitted-span {
    color: ${colors.brandLightBlue};
  }
`;

export const H4 = styled.h4`
  margin: 0;
  padding: 0;
  font-family: ${fonts.titles};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  line-height: 1.2;
  ${fluidRange({ prop: 'font-size', fromSize: '20px', toSize: '40px' })};
  color: ${colors.brandDarkBlue};

  @media ${md}, ${lg}, ${xl} {
    letter-spacing: -1px;
  }

  &.act-as-h1 {
    ${fluidRange({ prop: 'font-size', fromSize: '36px', toSize: '80px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -3px;
    }
  }

  &.act-as-h2 {
    ${fluidRange({ prop: 'font-size', fromSize: '30px', toSize: '66px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h3 {
    font-size: 40px;
    ${fluidRange({ prop: 'font-size', fromSize: '24px', toSize: '51px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h5 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  &.act-as-h6 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};
  }

  .splitted-span {
    color: ${colors.brandLightBlue};
  }
`;

export const H5 = styled.h5`
  margin: 0;
  padding: 0;
  font-family: ${fonts.titles};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  line-height: 1.2;
  ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};
  color: ${colors.brandDarkBlue};

  @media ${md}, ${lg}, ${xl} {
    letter-spacing: -1px;
  }

  &.act-as-h1 {
    ${fluidRange({ prop: 'font-size', fromSize: '36px', toSize: '80px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -3px;
    }
  }

  &.act-as-h2 {
    ${fluidRange({ prop: 'font-size', fromSize: '30px', toSize: '66px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h3 {
    font-size: 40px;
    ${fluidRange({ prop: 'font-size', fromSize: '24px', toSize: '51px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h4 {
    ${fluidRange({ prop: 'font-size', fromSize: '20px', toSize: '40px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  &.act-as-h6 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};
  }

  .splitted-span {
    color: ${colors.brandLightBlue};
  }
`;

export const H6 = styled.h6`
  margin: 0;
  padding: 0;
  font-family: ${fonts.titles};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  line-height: 1.2;
  ${fluidRange({ prop: 'font-size', fromSize: '12px', toSize: '20px' })};
  color: ${colors.brandDarkBlue};

  &.act-as-h1 {
    ${fluidRange({ prop: 'font-size', fromSize: '36px', toSize: '80px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -3px;
    }
  }

  &.act-as-h2 {
    ${fluidRange({ prop: 'font-size', fromSize: '30px', toSize: '66px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h3 {
    font-size: 40px;
    ${fluidRange({ prop: 'font-size', fromSize: '24px', toSize: '51px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -2px;
    }
  }

  &.act-as-h4 {
    ${fluidRange({ prop: 'font-size', fromSize: '20px', toSize: '40px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  &.act-as-h5 {
    ${fluidRange({ prop: 'font-size', fromSize: '16px', toSize: '30px' })};

    @media ${md}, ${lg}, ${xl} {
      letter-spacing: -1px;
    }
  }

  .splitted-span {
    color: ${colors.brandLightBlue};
  }
`;
