import { darken, lighten } from 'polished';
import Autosuggest from 'react-autosuggest';
import InputMask from 'react-input-mask';
import styled, { css } from 'styled-components';

import { globals } from '../globals';

const { fonts, sizes, colors } = globals;

const inputTextGray = '#676767';
const inputBorderGray = '#d2d2d2';

export const Label = styled.label`
  font-family: ${fonts.default};
  display: block;
  margin-bottom: 6px;
  font-size: 13px;
  color: #7d7d7d;

  .required {
    color: ${colors.red};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.red};
    `}
`;

export const InputLabelWrapper = styled.div`
  display: block;

  .error__message {
    color: ${colors.red};
  }
`;

// input sem mask
export const InputField = styled.input`
  width: 100%;
  background: white;
  border: 1px solid ${inputBorderGray};
  border-radius: 11px;
  height: ${sizes.default};
  line-height: 1;
  outline: none;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  color: ${inputTextGray};
  cursor: text;

  &:focus:not(:focus-visible) {
    border-color: ${darken(0.2, inputBorderGray)};
  }

  &:focus-visible {
    border-color: ${darken(0.2, inputBorderGray)};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: red;
    `}

  &:disabled {
    background-color: ${lighten(0.1, inputBorderGray)};
    cursor: not-allowed;
  }
`;

// input com mask
export const InputFieldMasked = styled(InputMask)`
  width: 100%;
  background: white;
  border: 1px solid ${inputBorderGray};
  border-radius: 11px;
  height: ${sizes.default};
  line-height: 1;
  outline: none;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  color: ${inputTextGray};

  &:focus:not(:focus-visible) {
    border-color: ${darken(0.2, inputBorderGray)};
  }

  &:focus-visible {
    border-color: ${darken(0.2, inputBorderGray)};
  }

  &:disabled {
    background-color: ${lighten(0.1, inputBorderGray)};
    cursor: not-allowed;
  }
`;

export const InputFieldWrapper = styled.div`
  position: relative;

  .loader {
    width: 26px;
    height: 26px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: ${(props) => (props.loading === 'true' ? 1 : 0)};
    transition: all 0.2s;
    pointer-events: none;
    margin-top: 2px;

    svg {
      -webkit-animation: rotate 2s linear infinite;
      animation: rotate 2s linear infinite;
      z-index: 2;
      position: absolute;
      margin: 0;
      width: 23px;
      height: 23px;
      stroke: red;
    }

    .path {
      stroke: ${colors.brandLightBlue};
      stroke-linecap: round;
      -webkit-animation: dash 1.5s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  .react-autosuggest__container {
    position: relative;

    .input--autosuggest {
      width: 100%;
      background: white;
      border: 1px solid ${inputBorderGray};
      border-radius: 11px;
      height: ${sizes.default};
      line-height: 1;
      outline: none;
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 1em;
      color: ${inputTextGray};

      &:focus:not(:focus-visible) {
        border-color: ${darken(0.2, inputBorderGray)};
      }

      &:focus-visible {
        border-color: ${darken(0.2, inputBorderGray)};
      }

      &:disabled {
        background-color: ${lighten(0.1, inputBorderGray)};
        cursor: not-allowed;
      }
    }

    /* .react-autosuggest__input {
      width: 240px;
      background:yellow;
      height: 30px;
      padding: 10px 20px;
      font-family: Arial, sans-serif;
      font-weight: 300;
      font-size: 16px;
      border: 1px solid #aaa;
      border-radius: 4px;
    } */

    .react-autosuggest__input--focused {
      outline: none;
    }

    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
      display: none;

      &.react-autosuggest__suggestions-container--open {
        max-height: 50vh;
        overflow-y: auto;
      }
    }

    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      top: 47px;
      width: 100%;
      border: 1px solid #aaa;
      background-color: #fff;
      font-family: Arial, sans-serif;
      font-size: 13px;
      border-radius: 11px;
      z-index: 2;
      overflow: hidden;
    }

    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 20px;
      border-top: 1px solid #d2d2d2;
      color: ${inputTextGray};
      text-transform: capitalize;

      &:first-child {
        border-top: none;
      }
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #f4f4f4;
    }

    .react-autosuggest__suggestion-match {
      color: ${colors.brandLightBlue};
    }
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const StyledAutosuggest = styled(Autosuggest)`
  .input--autosuggest {
    background: red !important;
    /* width:100%;
    background:white;
    border:1px solid ${inputBorderGray};
    border-radius:11px;
    height:${sizes.default};
    line-height:1;
    outline:none;
    display:block;
    padding-left: 10px;
    padding-right: 10px;
    font-size:1em;
    color: ${inputTextGray};

    &:focus:not(:focus-visible) {
      border-color: ${darken(0.2, inputBorderGray)};
    }

    &:focus-visible {
      border-color: ${darken(0.2, inputBorderGray)};
    } */
  }
`;
