import propTypes from 'prop-types';
import React from 'react';

import { CustomLabel, CustomSelect, SelectInputWrapper, SelectWrapper } from './styles';

export const SelectInput = React.forwardRef(
  ({ id, options, label, displayOptionalLabel, required, disabled, value, name, onChange, error, tabindex }, ref) => {
    const { hasError } = error;

    return (
      <SelectInputWrapper hasError={hasError}>
        <CustomLabel hasError={hasError}>
          {label}
          {required && <span className="required">*</span>}:{!required && displayOptionalLabel && <i> (opcional)</i>}
        </CustomLabel>
        <SelectWrapper hasError={hasError}>
          <CustomSelect
            hasError={hasError}
            id={id}
            required={required}
            disabled={disabled}
            value={value}
            ref={ref}
            name={name}
            onChange={(e) => onChange(e.target.value)}
            tabIndex={tabindex}
          >
            {options ? (
              options.map((option, idx) => (
                <option key={idx} value={option.id}>
                  {option.value}
                </option>
              ))
            ) : (
              <option value="">Nenhuma opção cadastrada</option>
            )}
          </CustomSelect>
          {hasError && <p className="error__message">{error.message}</p>}
        </SelectWrapper>
      </SelectInputWrapper>
    );
  },
);

SelectInput.propTypes = {
  id: propTypes.string.isRequired,
  label: propTypes.string,
  displayOptionalLabel: propTypes.bool,
  required: propTypes.bool,
  disabled: propTypes.bool,
  value: propTypes.string,
  options: propTypes.array.isRequired,
  onChange: propTypes.func.isRequired,
};

SelectInput.defaultProps = {
  error: {},
  onChange: () => {},
};

SelectInput.displayName = 'SelectInput';
