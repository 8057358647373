import { darken } from 'polished';
import styled from 'styled-components';

import { globals } from '../globals';

const { fonts, sizes, colors } = globals;

const inputTextGray = '#676767';
const inputBorderGray = '#d2d2d2';

export const TextareaLabelWrapper = styled.div`
  display: block;
`;
export const Label = styled.label`
  font-family: ${fonts.default};
  display: block;
  margin-bottom: 6px;
  font-size: 13px;
  color: #7d7d7d;

  .required {
    color: ${colors.red};
  }
`;

export const TextareaFieldWrapper = styled.div`
  position: relative;
`;

export const TextAreaField = styled.textarea`
  width: 100%;
  background: white;
  border: 1px solid ${inputBorderGray};
  border-radius: 11px;
  height: ${sizes.large};
  line-height: 1;
  outline: none;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  font-size: 1em;
  color: ${inputTextGray};

  &:focus:not(:focus-visible) {
    border-color: ${darken(0.2, inputBorderGray)};
  }

  &:focus-visible {
    border-color: ${darken(0.2, inputBorderGray)};
  }
`;
