import styled from 'styled-components';

import { globals } from '../globals';

const { mediaqueries, fonts } = globals;
const { xs, sm, md, lg, xl } = mediaqueries;
const quantityCols = 12;

export const Row = styled.div`
  display: flex;
  box-orient: horizontal;
  box-direction: normal;
  flex-flow: row wrap;
  font-family: ${fonts.default};

  margin-left: ${(props) => (props.gutter ? `-${props.gutter / 2}px` : '-20px')};
  margin-right: ${(props) => (props.gutter ? `-${props.gutter / 2}px` : '-20px')};

  & > .col {
    padding-left: ${(props) => (props.gutter ? `${props.gutter / 2}px` : '20px')};
    padding-right: ${(props) => (props.gutter ? `${props.gutter / 2}px` : '20px')};

    & > .col-inner {
      padding: ${(props) => (props.gutter ? `${props.gutter / 2}px` : `20px`)};
    }
  }
`;

const percentage = (size) => {
  return `${(size / quantityCols) * 100}%`;
};

const searchForFallbacks = (responsiveObject, mediaQuery) => {
  const fallbacks = {
    xl: ['xs', 'sm', 'md', 'lg'],
    lg: ['xs', 'sm', 'md'],
    md: ['xs', 'sm'],
    sm: ['xs'],
  };

  if (responsiveObject[mediaQuery] || mediaQuery === 'xs') {
    return responsiveObject[mediaQuery] || quantityCols;
  } else {
    let number = quantityCols;
    for (let index = 0; index < fallbacks[mediaQuery].length; index++) {
      const breakpoint = fallbacks[mediaQuery][index];
      if (responsiveObject[breakpoint]) {
        number = responsiveObject[breakpoint];
      }
    }

    return number;
  }
};

const colCalc = (responsiveObject, mediaQuery) => {
  const mq = mediaQuery || 'xs';
  return responsiveObject ? percentage(searchForFallbacks(responsiveObject, mq)) : '100%';
};

export const Col = styled.div`
  position: relative;
  max-width: 100%;
  min-height: 1px;
  display: block;

  @media ${xs} {
    flex: 0 0 ${(props) => colCalc(props.responsive, 'xs')};
    max-width: ${(props) => colCalc(props.responsive, 'xs')};
  }

  @media ${sm} {
    flex: 0 0 ${(props) => colCalc(props.responsive, 'sm')};
    max-width: ${(props) => colCalc(props.responsive, 'sm')};
  }

  @media ${md} {
    flex: 0 0 ${(props) => colCalc(props.responsive, 'md')};
    max-width: ${(props) => colCalc(props.responsive, 'md')};
  }

  @media ${lg} {
    flex: 0 0 ${(props) => colCalc(props.responsive, 'lg')};
    max-width: ${(props) => colCalc(props.responsive, 'lg')};
  }

  @media ${xl} {
    flex: 0 0 ${(props) => colCalc(props.responsive, 'xl')};
    max-width: ${(props) => colCalc(props.responsive, 'xl')};
  }
`;
