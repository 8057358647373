import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { H1, H2, H3, H4, H5, H6 } from './styles';

/**
 * Primary UI component for titles <br />
 * ```import { Title } from 'front-credpago/externals'```
 */
export const Title = ({ level, className, actAsLevel, label, children, fontWeight, splitLabel, style }) => {
  const levels = [H1, H2, H3, H4, H5, H6];
  const ActualTitle = levels[Number(level) - 1];
  const shouldBeSplitted = splitLabel.length > 0;

  const mapSplitted = (arr) => {
    return arr.map((item, idx) => {
      const isEven = idx % 2 === 0;

      if (isEven) {
        return <Fragment key={idx}>{item}</Fragment>;
      } else {
        return (
          <Fragment key={idx}>
            <span className="splitted-span"> {item} </span>
          </Fragment>
        );
      }
    });
  };

  return (
    <ActualTitle
      style={style}
      className={`${actAsLevel ? `act-as-h${actAsLevel}` : ''} ${className ? className : ''}`}
      fontWeight={fontWeight}
    >
      {shouldBeSplitted ? mapSplitted(splitLabel) : children || label}
    </ActualTitle>
  );
};

Title.propTypes = {
  /**
   * How large should the title be?
   */
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  label: PropTypes.string,
  splitLabel: PropTypes.array,
};

Title.defaultProps = {
  level: 1,
  actAsLevel: null,
  splitLabel: [],
};
