import styled, { css } from 'styled-components';

import { globals } from '../globals';

const textModifiers = {
  gray: () => css`
    color: ${globals.colors.gray};
  `,

  darkBlue: () => css`
    color: ${globals.colors.brandDarkBlue};
  `,
};

export const Description = styled.p`
  ${({ color }) => css`
    font-size: 17px;
    font-family: ${globals.fonts.default};
    font-weight: normal;

    ${color === 'gray' && textModifiers.gray()}
    ${color === 'darkBlue' && textModifiers.darkBlue()}

    @media ${globals.mediaqueries.xs}, ${globals.mediaqueries.sm} {
      font-size: 14px;
      text-align: center;
    }

    @media ${globals.mediaqueries.md} {
      text-align: center;
    }
  `}
`;
