import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

import { globals } from '../globals';

const { fonts, sizes, colors } = globals;

const inputTextGray = '#676767';
const inputBorderGray = '#d2d2d2';

export const SelectInputWrapper = styled.div`
  display: block;

  ${({ hasError }) =>
    hasError &&
    css`
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid ${colors.red} !important;
      }
    `}
`;

export const CustomLabel = styled.label`
  font-family: ${fonts.default};
  display: block;
  margin-bottom: 6px;
  font-size: 13px;
  color: #7d7d7d;

  .required {
    color: ${colors.red};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.red};
    `}
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;

  .error__message {
    color: ${colors.red};
  }
`;

export const CustomSelect = styled.select`
  width: 100%;
  border: 1px solid ${inputBorderGray};
  border-radius: 11px;
  line-height: 1;
  outline: none;
  display: block;
  padding: 10px;
  padding-right: 30px;
  font-size: 1em;
  color: ${inputTextGray};
  height: ${sizes.default};
  cursor: pointer;

  background: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 55"><path d="M0 7.5L7.6 0l39.9 39.9L87.4 0 95 7.5 47.5 55z"/></svg>')
    no-repeat right white;
  -webkit-appearance: none;
  background-position-x: 96%;
  background-size: 15px;

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${colors.red};
    `}

  &:focus:not(:focus-visible) {
    border-color: ${darken(0.2, inputBorderGray)};
  }

  &:focus-visible {
    border-color: ${darken(0.2, inputBorderGray)};
  }

  &:disabled {
    background-color: ${lighten(0.1, inputBorderGray)};
    cursor: not-allowed;
  }
`;
