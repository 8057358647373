import { Radio as RadioAntd } from 'antd';
import styled from 'styled-components';

import { globals } from '../globals';

export const Radio = styled(RadioAntd)`
  font-size: 15px;
  color: ${globals.colors.gray};
  font-family: ${globals.fonts.default};

  .ant-radio-inner::after {
    background-color: ${globals.colors.brandDarkBlue};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${globals.colors.brandDarkBlue};
  }
`;
