import styled from 'styled-components';

import { globals } from '../globals';

export const SocialNetworkWrapper = styled.ul`
  display: ${(props) => (props.horizontal ? 'flex' : 'block')};
  max-width: ${(props) => (props.horizontal ? (props.maxWidth ? `${props.maxWidth}px` : '140px') : '100%')};
  margin: 0;
  padding: 0;
  list-style: none;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  font-family: ${globals.fonts.titles};
  font-weight: bold;

  li {
    width: 100%;
    align-self: center;

    & + li {
      margin-left: ${(props) => (props.horizontal ? '10px' : '0')};
      margin-top: ${(props) => (props.horizontal ? '0' : '10px')};
    }

    a {
      display: ${(props) => (props.horizontal ? 'inherit' : 'flex')};
      text-decoration: none;
      color: ${globals.colors.brandDarkBlue};

      &:hover {
        svg {
          opacity: 0.8;
        }
      }

      svg {
        width: 100%;
        display: block;
        transition: opacity 0.2s;
        max-width: ${(props) => (props.horizontal ? 'none' : '32px')};
      }

      .socialNetworkName {
        margin-left: 0.8em;
        align-self: center;
      }
    }
  }
`;
