import styled from 'styled-components';

import { globals } from '../globals';

const { fonts, colors, mediaqueries } = globals;

const { xs, sm } = mediaqueries;

export const AccordionItemWrapper = styled.details`
  font-family: ${fonts.default};
  color: #7d7d7d;
  font-size: 15px;

  & + .accordion--item {
    margin-top: 1.4em;
  }

  &.accordion--open {
    summary {
      background: #f1f1f1 !important;
    }
  }

  summary {
    display: block;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 45px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 10px;
    color: ${colors.brandDarkBlue};

    @media ${xs}, ${sm} {
      padding-left: 15px;
      padding-right: 35px;
    }

    &:after {
      content: '';
      background-image: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='12px' height='7.4px' viewBox='0 0 12 7.4' style='enable-background:new 0 0 12 7.4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23001c4a;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cpath class='st0' d='M1.4,0L6,4.6L10.6,0L12,1.4l-6,6l-6-6L1.4,0z'/%3E%3C/svg%3E%0A");
      background-size: 100% 100%;
      width: 20px;
      height: 10px;
      display: block;
      position: absolute;
      right: 45px;
      top: 50%;
      transform: translateY(-50%);

      @media ${xs}, ${sm} {
        right: 15px;
      }
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${colors.brandLightBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: 0 0 0 2px ${colors.brandLightBlue};
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px ${colors.brandLightBlue};
    }

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }

    &:hover {
      background: #f1f1f1;
    }
  }

  main {
    padding: 15px 45px;

    @media ${xs}, ${sm} {
      padding: 15px;
    }
  }
`;
