import React, { useState } from 'react';

import { AccordionItemWrapper } from './styles';

export const AccordionItem = ({ title, children, onClick, ...rest }) => {
  const [isActive, setActive] = useState(false);

  const clickHandler = (event) => {
    setActive(!isActive);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <AccordionItemWrapper className={`accordion--item ${isActive ? 'accordion--open' : 'accordion--closed'}`} {...rest}>
      <summary onClick={clickHandler}>{title}</summary>
      <main>{children}</main>
    </AccordionItemWrapper>
  );
};
