const globals = {
  containerMaxWidth: '1400px',
  paddingSides: '1.3em',
  lineHeight: 1.4,
  fonts: {
    sans: 'Roboto, sans-serif',
    serif: 'Georgia, serif',
    default: 'Roboto, sans-serif',
    headings: '"Uni Neue", Roboto, sans-serif',
  },
  colors: {
    darkBlue: '#113c64',
    lightBlue: '#2ac4ef',
    lightGray: '#9f9f9f',
    yellow: '#ffcc0e',
    danger: '#EF3E31',
    green: '#32BA7C',
  },
  mediaqueries: {
    darkmode: '(prefers-color-scheme: dark)',
  },
  inputSizes: {
    small: '40px',
    medium: '60px',
    large: '70px',
  },
};

export default globals;
