import { Image } from '@loft/react-credpago';
import { logo } from '@loft/tokens-credpago';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';

import { Button } from '../Button';
import {
  ActionsWrapper,
  CenteredContainer,
  HeaderContainer,
  LogoWrapper,
  MenuWrapper,
  MobileMenuList,
  MobileMenuWrapper,
} from './styles';

export const Header = ({
  onLogoClick,
  onEnter,
  onSignUp,
  menu,
  style,
  navConfig,
  signInText,
  signUpText,
  textBelowSignIn,
  onOpenService,
  onOpenServiceText,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const renderLink = (item) => {
    const isExternalLink = item.children ? false : item.href.match('http://') || item.href.match('https://');
    const isForcingNormalLink = Boolean(item.forceNormalLink);

    if (navConfig && !isExternalLink && !isForcingNormalLink) {
      const obj = {
        [navConfig.hrefProp]: item.href,
      };
      let Component = (args) => <navConfig.component {...args} />;
      return <Component {...obj}>{item.label}</Component>;
    } else {
      const target = isExternalLink ? '_blank' : '_self';
      if (isExternalLink) {
        return (
          <a target={target} rel="noopener" href={item.href}>
            {item.label}
          </a>
        );
      } else {
        return (
          <a target={target} href={item.href}>
            {item.label}
          </a>
        );
      }
    }
  };

  const renderMenu = (type) => {
    const isMobileMenu = type === 'mobile';

    return menu.length > 0 ? (
      <ul>
        {menu.map((item, index) => {
          let hasSubmenu = item.children;
          return (
            <li key={index}>
              {isMobileMenu && hasSubmenu ? (
                <details>
                  <summary>{item.label}</summary>
                  <main>
                    <ul>
                      {item.children.map((submenu, idx) => (
                        <li key={idx}>{renderLink(submenu)}</li>
                      ))}
                    </ul>
                  </main>
                </details>
              ) : (
                <Fragment>
                  {renderLink(item)}
                  {item.children && (
                    <ul>
                      {item.children.map((submenu, idx) => (
                        <li key={idx}>{renderLink(submenu)}</li>
                      ))}
                    </ul>
                  )}
                </Fragment>
              )}
            </li>
          );
        })}
      </ul>
    ) : (
      <ul>
        <li>
          <a href="/servicos">Serviços</a>
          <ul>
            <li>
              <a href="/servicos/servico-um">Serviço um</a>
            </li>
            <li>
              <a href="/servicos/servico-dois">Serviço dois</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="/encontrar-imobiliarias">Encontrar Imobiliárias</a>
        </li>
        <li>
          <a href="/faq">FAQ</a>
        </li>
        <li>
          <a href="/contato">Contato</a>
        </li>
        <li>
          <a href="/blog">Blog</a>
        </li>
      </ul>
    );
  };

  return (
    <HeaderContainer style={style} className="header">
      <CenteredContainer>
        <LogoWrapper onClick={onLogoClick}>
          <Image src={logo.primary.small.svg} />
        </LogoWrapper>
        <MenuWrapper>{renderMenu()}</MenuWrapper>
        <ActionsWrapper>
          <span style={{ marginRight: '6em' }}>
            <Button className="has-text-below" onClick={onOpenService}>
              {onOpenServiceText}
            </Button>
          </span>

          <Button type="secondary" onClick={onSignUp}>
            {signUpText}
          </Button>

          <Button className={textBelowSignIn ? 'has-text-below' : ''} onClick={onEnter}>
            {signInText}
          </Button>
          {textBelowSignIn && <span className="text-below-signin">{textBelowSignIn}</span>}
        </ActionsWrapper>
        <MobileMenuWrapper isActive={menuOpen}>
          <button onClick={toggleMenu}>
            <span>MENU</span>
            <svg className="menu-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path fill="none" d="M0 0h16v16H0z" />
              <g fill="#7d7d7d">
                <path d="M0 1h16v2H0z" />
                <path d="M0 7h16v2H0z" />
                <path d="M0 13h8v2H0z" />
              </g>
            </svg>
          </button>
          <MobileMenuList isActive={menuOpen}>
            {renderMenu('mobile')}

            <div style={{ margin: '2em 0' }}>
              <Button blocked type="default" onClick={onOpenService}>
                {onOpenServiceText}
              </Button>
            </div>

            <Button blocked type="secondary" inverted onClick={onSignUp}>
              {signUpText}
            </Button>
            <Button blocked type="default" onClick={onEnter}>
              {signInText}
            </Button>
            {textBelowSignIn && <span className="text-below-signin-mobile">{textBelowSignIn}</span>}
          </MobileMenuList>
        </MobileMenuWrapper>
      </CenteredContainer>
    </HeaderContainer>
  );
};

Header.propTypes = {
  // Optional Click handler
  onLogoClick: PropTypes.func,
  // Optional Click handler
  onEnter: PropTypes.func,
  // Optional Click handler
  onSignUp: PropTypes.func,
  // Optional Menu Override
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      children: PropTypes.array,
    }),
  ),
  signInText: PropTypes.string,
  signUpText: PropTypes.string,
  textBelowSignIn: PropTypes.string,
};

Header.defaultProps = {
  menu: [],
  onLogoClick: (e) => e,
  onEnter: (e) => e,
  onSignUp: (e) => e,
  signInText: 'Entrar',
  signUpText: 'Cadastre-se',
  textBelowSignIn: '',
};
