import { darken, lighten } from 'polished';
import styled from 'styled-components';

import { globals } from '../globals';

const { fonts, colors, mediaqueries, siteMaxWidth, sitePaddingSides } = globals;
const { xs, sm, md, lg, xl } = mediaqueries;

export const HeaderContainer = styled.header`
  width: 100%;
  display: block;
`;

export const CenteredContainer = styled.div`
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: ${siteMaxWidth};
  padding-left: ${sitePaddingSides};
  padding-right: ${sitePaddingSides};
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: 120px;
  cursor: pointer;
  font-size: 0; /* spacing fix */
`;

export const ActionsWrapper = styled.aside`
  margin-right: 0;
  margin-left: 0;
  position: relative;

  @media ${xs}, ${sm}, ${md} {
    display: none;
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    display: none;
  }

  .button + .button {
    margin-left: 1em;
  }

  .has-text-below {
    min-width: 132px;
  }

  .text-below-signin {
    position: absolute;
    top: 100%;
    right: 0;
    color: ${colors.brandDarkBlue};
    font-size: 12px;
    transform: translateY(10px);
    margin-top: -5px;
  }
`;

export const MenuWrapper = styled.nav`
  font-family: ${fonts.titles};
  margin: 0 auto;
  font-weight: 500;

  @media ${xs}, ${sm}, ${md} {
    display: none;
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }
  }

  & > ul {
    & > li {
      position: relative;
      display: inline-block;
      padding: 0;

      &:hover {
        a {
          padding-top: 14px;
          padding-bottom: 14px;

          /* transitions */
          & + ul {
            width: 210px;
            opacity: 1;
            font-size: 0.9em;

            a {
              color: #777;
            }
          }
        }
      }

      & > a {
        padding-top: 14px;
        padding-bottom: 14px;
        display: block;
        color: #666;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: #010101;
        }
      }

      & + li {
        margin-left: 3em;

        @media ${md} {
          margin-left: 2em;
        }
      }

      ul {
        background: #fbfbfb;
        position: absolute;
        left: 50%;
        top: 90%;
        width: 0%;
        font-size: 2px;
        transform: translateX(-50%);
        border-radius: 15px;
        text-align: center;
        opacity: 0;
        transition: all 0.2s;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);

        & > li {
          &:first-child {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            overflow: hidden;
          }

          &:last-child {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            overflow: hidden;
          }

          a {
            text-decoration: none;
            line-height: 1;
            display: block;
            padding-top: 2px;
            padding-bottom: 2px;
            color: #777777;
            transition: all 0.1s;

            &:hover {
              background: ${colors.brandDarkBlue};
              color: white;
            }
          }
        }
      }
    }
  }
`;

export const MobileMenuWrapper = styled.div`
  margin-right: 0;
  margin-left: auto;
  position: relative;

  @media ${lg}, ${xl} {
    display: none;
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    display: inherit;
  }

  button {
    background: ${(props) => (props.isActive ? colors.brandDarkBlue : 'none')};
    border: none;
    cursor: pointer;
    padding: 7px 8px;
    outline: none;
    border-radius: ${(props) => (props.isActive ? '7px 7px 0 0' : '7px')};

    &:hover {
      svg g path {
        fill: ${(props) => (props.isActive ? 'inherit' : lighten(0.2, colors.brandDarkBlue))};
      }
    }

    &:active {
      background: ${lighten(0.5, colors.brandLightBlue)};
    }

    &:focus:not(:focus-visible) {
      box-shadow: ${(props) => (props.isActive ? 'none' : `0 0 0 2px ${colors.brandLightBlue}`)};
    }

    &:focus-visible {
      box-shadow: ${(props) => (props.isActive ? 'none' : `0 0 0 2px ${colors.brandLightBlue}`)};
    }

    span {
      padding-right: 13px;
      font-weight: bold;
      color: ${(props) => (props.isActive ? 'white' : `#7D7D7D`)};
      display: inline-block;
      transform: translateY(-2px);
    }

    svg {
      width: 16px;
      height: auto;
      display: inline-block;

      g path {
        fill: ${(props) => (props.isActive ? 'white' : '#7D7D7D')};
      }
    }
  }

  details {
    font-family: ${fonts.titles};
    color: white;

    summary {
      cursor: pointer;
      border-radius: 7px;
      position: relative;
      padding-right: 13px;

      &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='12px' height='7.4px' viewBox='0 0 12 7.4' style='enable-background:new 0 0 12 7.4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cpath class='st0' d='M1.4,0L6,4.6L10.6,0L12,1.4l-6,6l-6-6L1.4,0z'/%3E%3C/svg%3E%0A");
        background-size: 100% 100%;
        width: 10px;
        height: 6px;
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:focus {
        outline: none;
      }

      &::marker {
        display: none;
      }

      &:active {
        color: white;
      }

      &:focus:not(:focus-visible) {
        color: ${colors.brandLightBlue};
      }

      &:focus-visible {
        color: ${colors.brandLightBlue};
      }
    }
  }
`;

export const MobileMenuList = styled.nav`
  width: 210px;
  background: ${colors.brandDarkBlue};
  font-family: ${fonts.titles};
  position: absolute;
  right: 0;
  top: 100%;
  padding: 20px;
  border-radius: 7px 0 7px 7px;
  text-align: center;
  opacity: ${(props) => (props.isActive ? '1' : '0')};
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: ${(props) => (props.isActive ? 'opacity .3s .1s, transform .2s .2s' : 'opacity .3s .2s, transform .2s')};
  transform: ${(props) => (props.isActive ? 'scale(1)' : 'scale(0)')};
  transform-origin: 100% 0;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      width: 100%;
      display: block;

      & > a {
        color: white;
        text-decoration: none;
        padding: 6px 0;
        display: block;

        &:hover {
          color: ${colors.brandLightBlue};
        }

        &:active {
          color: ${darken(0.1, colors.brandLightBlue)};
        }
      }
    }
  }

  button {
    border-radius: 7px;
    margin-top: 15px;

    span:not(.icon-wrapper) {
      padding-right: 0;
    }
  }

  .text-below-signin-mobile {
    color: ${colors.brandLightBlue};
    text-align: center;
    font-size: 12px;
    font-family: ${fonts.default};
    padding-top: 8px;
    display: block;
  }
`;
