import propTypes from 'prop-types';
import React, { useState } from 'react';

import { TabPanelsWrapper, TabsWrapper } from './styles';

export const Tabs = ({ activeIndex, children }) => {
  const [actualActiveKey, setActualActiveKey] = useState(activeIndex);

  return (
    <TabsWrapper>
      <ol role="tablist" aria-label="Tabs">
        {children.map((item, idx) => {
          const { title } = item.props;
          return (
            <TabTitle key={idx} isActive={actualActiveKey === idx} onClick={() => setActualActiveKey(idx)}>
              {title}
            </TabTitle>
          );
        })}
      </ol>
      <TabPanelsWrapper className="tabpanels">
        <div role="tabpanel">
          {children.map((item, idx) => {
            return (
              <Tab key={idx} style={{ display: actualActiveKey === idx ? 'block' : 'none' }}>
                {item.props.children}
              </Tab>
            );
          })}
        </div>
      </TabPanelsWrapper>
    </TabsWrapper>
  );
};

// const TabPane = ({ title, key }) => {

//   return (
//     <button
//       onClick={() => onChange(key)}
//       role="tab"
//       aria-selected="false">
//       {title}
//     </button>
//   )
// }

const TabTitle = ({ children, onClick, isActive }) => (
  <li role="tab" className={isActive ? 'is-active' : ''}>
    <button onClick={onClick}>{children}</button>
  </li>
);

export const Tab = ({ children, style }) => {
  return (
    <div className="tab-content" style={style} role="tabpanel">
      {children}
    </div>
  );
};

Tabs.propTypes = {
  activeIndex: propTypes.number,
};

Tabs.defaultProps = {
  activeIndex: 0,
};
